
    .practical-content{
        width: 1200px;
        margin: 0 auto;
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        .practical-details{
            height: calc(100% - 55px);
            display: flex;
            flex-direction: column;
            flex: 1;
            background-color: #fff;
           ::v-deep .el-tabs {
                height: 100%;
                display: flex;
                flex-direction: column;
                .el-tabs__content {
                    flex: 1;
                    height: calc(100% - 80px);
                    .el-tab-pane {
                        height: 100%;
                    }
                }
            }
            ::v-deep.el-tabs__item.is-active{
                color: #fff;
                background: #409EFF;
            }
            ::v-deep.el-tabs__active-bar{
                background-color: transparent;
            }
            ::v-deep.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            ::v-deep.el-tabs--top .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header{
                padding: 20px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
                border: 1px solid #409eff;
                height: 40px;
                border-radius: 4px;
            }
            ::v-deep.el-tabs__header{
                margin: 0;
            }
        }

        ::v-deep .el-breadcrumb {
            padding: 20px 0;
            .el-breadcrumb__inner {
                color: #000;
            }
        }
    }
